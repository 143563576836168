import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useAccount from '@/hooks/useAccount';

import Loading from '../components/utility/Loading';

export default function PaypalCallbackPage() {
  const [params] = useSearchParams();
  const { unhurdUser } = useAccount();
  const other: string | null = params.get('other');
  const code: string | null = params.get('ppcode');
  const error_uri: string | null = params.get('error_uri');

  useEffect(() => {
    if (!unhurdUser) return;
    if (code && other) {
      window.opener?.postMessage({ code: code, route: other }, '*');
      window.close();
    }
  }, [code, error_uri, other, unhurdUser]);

  return (
    <>
      <div className="onboarding-page">
        <div className="centered-loading mt48">
          <Loading />
        </div>
      </div>
    </>
  );
}
